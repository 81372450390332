// ==========================  colors
$green: #64AE5F;
$primary: $green;
$gray-custom: #A7A9B7;
$light-green: rgba(14, 224, 0, 0.35);

// ==========================  containers
$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1340px,
);
// ==========================  containers-end

// ==========================  fonts
//$font-size-base: 1rem;
$font-family-base: "Mont", sans-serif;

// ==========================  grid
$enable-cssgrid: false;

// ==========================  link
$link-decoration: none;

// ==========================  modal 
$modal-content-border-width: 0;
$modal-content-border-radius: 17px;
$modal-backdrop-opacity: 0.6;
$modal-fade-transform: translate(0, 100%);
$modal-transition: transform .4s ease-out;
$modal-dialog-margin: 0;

// ==========================  close 
//$btn-close-color: white;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.5;

//========================== accordion
$accordion-bg:                            white;
$accordion-border-color:                  $primary;
$accordion-border-radius:                 10px;

$accordion-button-color:                  white;
$accordion-button-bg:                     $primary;
$accordion-button-active-bg:              $primary;
$accordion-button-active-color:           white;

$accordion-button-focus-box-shadow:       none;

$accordion-border-width:				  1px;

// $accordion-icon-width:                    1.25rem !default;
// $accordion-icon-color:                    $accordion-button-color !default;
// $accordion-icon-active-color:             $accordion-button-active-color !default;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
// $accordion-icon-transform:                rotate(-180deg) !default;

// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

// ========================== header 
$header-height: 70px;

// ========================== nav 
$nav-width: 220px;


// ========================== table 
$table-striped-bg-factor: 0.2;
$table-striped-bg: rgba($primary, $table-striped-bg-factor);



