.login-page {
    @include media-breakpoint-up(md) {
        background: url("../../../assets/img/backgrounds/bg.svg") no-repeat;
        background-position: bottom;
        background-size: 100%;
    }

    &__login {
        width: 446px;
        max-width: 100%;
        margin: auto;

        @include media-breakpoint-up(md) {
            margin-bottom: 270px;
            background: white;
        }
    }
}
