.nav {
    font-size: 0.9rem;

    &__link {
        color: rgba(143, 143, 143, 1);
        display: flex;
        align-items: center;

        svg {
            stroke: rgba(143, 143, 143, 1);
        }

        &--active {
            color: black;

            svg {
                stroke: $primary;
            }
        }
    }

    &__category-icon {
        background-color: $primary;
        border-radius: 5px;
        width: 39px;
        height: 17px;

        svg {
            height: 80%;
        }
    }

    &__children {
        margin-left: 38px;
    }

    &__circle {
        width: 8px;
        height: 8px;
        background: rgba(143, 143, 143, 1);
        border-radius: 10px;

        .nav__link--active & {
            background: $primary;
        }
    }
}