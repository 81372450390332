@font-face {
	font-family: "Mont";
	src: 	local("Mont-Bold"), 
			local("Mont Bold"), 
			url("../fonts/Mont-Bold.woff2") format("woff2"),
			url("../fonts/Mont-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mont";
	src: 	local("Mont-SemiBold"), 
			local("Mont SemiBold"),
			url("../fonts/Mont-SemiBold.woff2") format("woff2"),
			url("../fonts/Mont-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mont";
	src: local("Mont-Regular"), 
		local("Mont Regular"),
		url("../fonts/Mont-Regular.woff2") format("woff2"),
		url("../fonts/Mont-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
