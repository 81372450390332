// ========================== main 

html, body {
    height: 100%;
}

body, #root {
    min-height: 100vh;
}

input:-webkit-autofill {
    box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
    -webkit-text-fill-color: black !important; /* цвет текста */
    color: black !important; /* цвет текста */
    font-size: 16px;
}

// ========================== cursor 

.cursor-pointer {
    cursor: pointer;
}

// ========================== links 

a:hover {
    opacity: 0.9;
}

// ========================== text 

.text-gray-custom {
    color: $gray-custom;
}

// ========================== table

thead {
    vertical-align: middle!important;
}

tbody {
    border-color: $green !important;
    td, tr {
        border-color: $green !important;
    }
}

// ========================== bg

.bg-light-green {
    background: $light-green;
}

.calendar-date-range {
    box-shadow: 0px 5px 36px -3px rgba(34, 60, 80, 0.2);
    border-radius: 0 0 10px 10px !important;
    width: auto!important;
    height: auto!important;
    overflow: visible!important;
    max-height: none!important;
    z-index: 1000;

    .time-pic {
        padding: 0 48px;
        margin-top: 15px;
        width: 220px;
        margin-bottom: 20px;
        display: block;
        width: 100%;
        .field {
            width: 100%;
        }
        input {
            border: 1px solid #dff3f5;
            padding: 10px 15px;
            border-radius: 8px;
        }
        input::-webkit-calendar-picker-indicator {
            //background: url("../../../imgs/features/Alarm-green.svg") right center no-repeat;
            width: 50%;
        }
    }

    .rdrMonth {
        padding-bottom: 5px !important;
    }

    .rdrMonthName {
        text-align: center;
    }

    .rdrMonthAndYearWrapper {
        height: 16px;
    }

    .rdrDateRangePickerWrapper {
        .rdrCalendarWrapper {
            padding-left: 40px;
            padding-right: 40px;
            border-radius: 0 0 10px 10px !important;
        }
    }

    .rdrNextPrevButton {
        background-color: #61a95d;
        border-radius: 100%;
        width: 46px;
        height: 46px;
        z-index: 100;

        i {
            display: none !important;
        }
    }

    .rdrNextPrevButton {
        position: absolute;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    @include media-breakpoint-down(sm) {
        .rdrNextPrevButton {
            top: 4%;
        }
        .rdrDateRangePickerWrapper {
            width: 100% !important;
            .rdrCalendarWrapper {
                padding-left: 0;
                padding-right: 0;
                width: 100% !important;
                .rdrMonth {
                    width: 100% !important;
                }
            }
        }

        .rdrPprevButton,
        .rdrNextButton {
            display: none;
        }
    }

    .rdrPprevButton {
        left: -30px;
        background-image: url("data:image/svg+xml;utf8,<svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17.5001 4.66666L8.16675 14L17.5001 23.3333' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /></svg>");
    }

    .rdrNextButton {
        right: -30px;
        background-image: url("data:image/svg+xml;utf8,<svg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.50004 1.66666L10.8334 11L1.50004 20.3333' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /></svg>");
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: #000 !important;
    }

    .rdrDayEndPreview,
    .rdrDayStartPreview {
        border: none;
    }

    .rdrDayToday {
        .rdrDayNumber {
            span {
                &:after {
                    display: none !important;
                }
            }
        }
    }

    .rdrDayInPreview {
        color: #000 !important;

        .rdrDayNumber {
            span {
                color: #000 !important;
            }
        }

        border: none !important;
    }

    .rdrDayStartPreview,
    .rdrDayEndPreview {
        border-radius: 0 !important;
    }

    .rdrDayStartOfWeek {
        .rdrInRange {
            border-radius: 4px 0 0 4px !important;
        }
    }

    .rdrDayEndOfWeek {
        .rdrInRange {
            border-radius: 0 4px 4px 0 !important;
        }
    }

    .rdrStartEdge,
    .rdrEndEdge {
        background-color: #61a95d !important;
        border-radius: 4px !important;
        padding: 10px 6px;
    }

    .rdrDayHovered {
        color: #fff !important;

        .rdrDayNumber {
            background-color: #61a95d !important;
            border-radius: 4px;
            color: #fff !important;

            span {
                color: #fff !important;
            }
        }
    }

    .rdrInRange {
        background-color: #f2f2f2 !important;
        border-radius: 0;
        padding: 10px 6px;
    }

    .rdrDefinedRangesWrapper {
        display: none;

        .rdrStaticRanges {
            display: none;
        }
    }

    .rdrMonthAndYearPickers {
        display: none;
    }
}

