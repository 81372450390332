.mutual-settlement-page {
    &__bus {
        border-radius: 10px;
        border: 1px solid $primary;
    }

    &__table-wrapper {
        border-radius: 10px;
        overflow: hidden;
        .route-table {
            height: 0px;
            &.opened {
                height: 100%;
            }
        }
    }

    &__table {
        td {
            padding: 3px 10px;
            text-align: center;
        }

        tbody {
            // tr {
            //     &:first-of-type {
            //         font-weight: 800;
            //     }
            // }
            svg {
                path {
                    stroke: $green;
                }
                transform: rotate(0deg);
                transition: 0.3s;
            }
            .bg-light-green {
                svg {
                    path {
                        stroke: black;
                    }
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__button {
        //min-width: 184px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        display: block !important;
    }
}
