.role-switcher {
    .switcher-button {
        transition: transform 0.2s ease-in-out;
        border-radius: 9px;
        border: 1px solid transparent;
        &:hover {
            transform: scale(101%);
            border: 1px solid #A7A9B7;
        }
    }
}
