.client-footer {
    &__first-row-title {
        color: #2e3134;
        font-size: 28px;
        @include media-breakpoint-down(lg) {
            font-size: 18px;
        }
    }
    &__first-row-subtitle {
        font-size: 48px;
        @include media-breakpoint-down(lg) {
            font-size: 26px;
        }
    }
    .rounded-wrap {
        min-height: 70px;
        border-radius: 20px;
        @include media-breakpoint-down(lg) {
            min-height: auto;
            border-radius: 14px;
        }
    }
    .bg-bg {
        background-color: #f2f2f2;
        @include media-breakpoint-down(lg) {
            background-color: transparent;
        }
    }
    .social-links {
        a {
            margin: 0 35px;
            @include media-breakpoint-down(xxl) {
                margin: 0 18px;
            }
            @include media-breakpoint-down(xl) {
                margin: 0 10px;
            }
        }
    }
    .store-link {
        height: 100%;
        padding: 6px 15px;
        background: #2e3134;
        color: #fff;
        @include media-breakpoint-down(xxl) {
            padding: 6px 20px;
        }
        @include media-breakpoint-down(lg) {
            padding: 8px 10px;
        }
        .title {
            font-size: 11px;
            @include media-breakpoint-down(xxl) {
                font-size: 10px;
            }
        }
        &._apple {
            .logo {
                width: 29px;
                @include media-breakpoint-down(xxl) {
                    width: 26px;
                }
            }
        }
        &._google {
            .logo {
                width: 34px;
                @include media-breakpoint-down(xxl) {
                    width: 28px;
                }
            }
        }
        @include media-breakpoint-down(xxl) {
            .store-logo {
                height: 20px;
            }
        }
    }
    .reg-lk-block {
        padding: 11px;
        .button {
            @include media-breakpoint-down(lg) {
                width: 50%;
            }
        }
    }
    .button--white {
        @include media-breakpoint-down(lg) {
            background-color: #f2f2f2;
        }
    }
    .btn-scroll-to-top {
        width: 3rem;
        position: absolute;
        right: 50px;
        bottom: 30px;
        opacity: 0.5;
        height: 50px !important;
        min-height: 50px !important;
        transition: all 0.3s ease 0s;
    }
    .footer-links {
        a {
            font-size: 13px;
        }
        .title {
            font-size: 24px;
        }
    }
    .thumb-text {
        font-size: 11px;
        color: #b5a7b4;
    }
}
