.home-page {
    height: 100%;

    &__title {
        font-weight: 600;
        text-align: center;
    }

    &__card-icon-block {
        width: 60px;
        flex-grow: 1;

        svg {
            max-width: 80%;
            height: 50px;
        }
    }

    &__to-col {
        width: 50px;
    }

    &__calendar {
        min-width: 310px;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}