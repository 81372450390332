.mutual-settlement-ferryman-page {
    &__bus {
        border-radius: 10px;
        border: 1px solid $primary;
    }

    &__table-wrapper {
        border-radius: 10px;
        overflow: hidden;
    }

    &__table {
        td {
            padding: 3px 10px;
            text-align: center;
        }
        tbody {
            tr {
                &:first-of-type {
                    font-weight: 800;
                }
            }
            svg {
                path {
                    stroke: $green;
                }
                transform: rotate(0deg);
                transition: 0.3s;
            }
            .bg-light-green {
                svg {
                    path {
                        stroke: black;
                    }
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__fake-td {
        width: 100%;
        background-color: white;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
