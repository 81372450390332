.header {
    background-image: url("../../../assets/img/backgrounds/bus.png");
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
    height: $header-height;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        content: "";
        z-index: 2;
    }

    &__content {
        z-index: 3;
        position: relative;
    }

    &__logo-title {
        color: white;
        font-weight: 700;

        @include media-breakpoint-up(md) {
            font-size: 21px;
            line-height: 23px;
        }
    }

    &__logo-subtitle {
        font-size: 10px;
        color: white;
        opacity: 0.8;

        @include media-breakpoint-up(md) {
            font-size: 11px;
        }
    }

    &__logo {
        width: 40px;
        height: auto;

        @include media-breakpoint-up(md) {
            width: 50px;
        }
    }

    &__menu {
        fill: white;
    }

    &__profile {
        width: 250px;
        max-width: 100%;
        border: 1px solid white;
        height: 34px;
        border-radius: 10px;
        cursor: pointer;
        &-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__logout {
        border-radius: 9px;
        border: 1px solid #a7a9b7;
        transition: transform 0.3s ease-in-out;
        &:hover {
            border: 1px solid #64ae5f;
            transform: scale(101%);
        }
    }
}
