.partner-layout {
    min-height: 100%;
    position: relative;
    
    &__header {
        position: sticky;
        top: 0;
        z-index: 100;
    }

    &__container {
        @include media-breakpoint-up(md) {
            border: 1px solid $primary;
            border-top: none;
        }
    }

    &__nav {
        position: sticky;
        top: $header-height;
        width: $nav-width;
    }

    &__page {
        width: 100%;

        @include media-breakpoint-up(md) {
            border-left: 1px solid $primary;
        }
    }

    &__grid {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: auto calc(100% - 220px);
        }
    }
}