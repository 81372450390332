.client-header {
    &__logo-title {
        color: black;
        font-weight: 700;

        @include media-breakpoint-up(md) {
            font-size: 27px;
            line-height: 30px;
        }
    }

    &__logo-subtitle {
        font-size: 10px;
        color: #2E3134;
        opacity: 0.5;

        @include media-breakpoint-up(md) {
            font-size: 11px;
        }
    }

    &__nav-elem {
        color: $gray-custom;
        font-size: 14px;
    }

    &__phone {
        color: black;
        font-size: 20px;
    }

    &__logo {
        width: 40px;

        @include media-breakpoint-up(md) {
            width: 60px;
        }
    }
}
