.data-line {
    font-size: 15px;
    display: grid;
    grid-template-columns: auto 1fr auto;

    &--bold {
        font-weight: 600;
    }

    &__line {
        border-bottom: 1px solid $primary;

        &--no-line {
            border-bottom: none;
        }
    }
}