.input {
    border: 1px solid $primary;
    border-radius: 10px;

    &__label {
        font-size: 12px;
        font-weight: 600;
    }

    &__input {
        font-size: 16px;
        border: none;
        outline: none;
        width: 100%;

        &::placeholder {
            color: #A7A9B7;
            font-size: 16px;
        }
    }
}
