.select {
    background: transparent;
    border-radius: 10px;
    border: 1px solid white;

    &--primary {
        border-color: $primary;
    }

    &__label {
        font-size: 13px;
        font-weight: bold;
        opacity: 0.7;
    }

    .react-select-main {
        &__control {
            border: none !important;
            box-shadow: none !important;
            background: transparent !important;
            padding: 0 0 0 1rem !important;
            min-height: auto !important;
        }

        &__input-container {
            margin: 0 !important;
            padding: 0 !important;
            color: white !important;
        }

        &__indicator-separator {
            display: none;
        }

        &__dropdown-indicator {
            padding: 0 4px !important;
            margin-right: 5px;
        }

        &__single-value {
            margin: 0 !important;
            color: white !important;
        }

        &__clear-indicator {
            padding: 0 4px !important;
        }

        &__value-container {
            margin: 0 !important;
            padding: 0 !important;
            color: white !important;
        }

        &__menu {
            //background: $gray-custom!important;
        }

        &__menu-list {
            width: 100% !important;
            padding: 7px 5px;
        }

        &__option {
            color: black !important;
            font-size: 14px;
            padding: 5px 7px !important;
            cursor: pointer;
            font-weight: 600;

            &:hover {
                background: rgba(1, 1, 1, 0.2);
            }

            &--is-focused {
                //background: $gray-2!important;
            }

            &--is-selected {
                background: $primary !important;
                color: white !important;
            }
        }
    }
}

.select--primary {
    .react-select-main {
        &__single-value {
            margin: 0 !important;
            color: #2e3134 !important;
        }
    }
}
