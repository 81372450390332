.dropdown {
    position: absolute;
    top: 100%;
    margin-top: 2px;
    width: 100%;
    max-height: 200px;
    background: white;
    color: black;
    border-radius: 10px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
    transition: opacity max-height 0.3s ease-in-out;
    overflow: unset;
    display: block;

    &--right {
        right: 0;
    }

    &--fast {
        transition: max-height 0.1s ease-in-out;
    }

    &--no-animation {
        transition: none;
    }

    &--closed {
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        &.dropdown--no-animation {
            display: none;
        }
    }
}