.button {
    border-radius: 16px;
    font-size: 14px;
    height: 46px;
    cursor: pointer;
    transition: all 0.3s;
    border: none;
    
    &:hover {
        opacity: 0.9;
    }

    &--primary {
        background: $primary;
        color: white;

        &:hover {
            color: white;
        }
    }

    &--white {
        background: white;
        color: #2e3134;
        
        &:hover {
            color: #2e3134;
        }
    }

    &--transparent {
        background: transparent;
        color: #2e3134;
        border: 1px solid #d6d6d6;

        &:hover {
            background: white;
        }
    }

    &--big {
        padding: 15px 24px !important;
        height: auto;
    }

    &--white-partner {
        background: white;
        border: 1px solid $primary;
        border-radius: 10px;
    }

    &--white-partner-active {
        background: $primary;
        border: 1px solid $primary;
        border-radius: 10px;
        color: white;
    }

    &--partner {
        height: auto;
    }
}
